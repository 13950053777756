// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../../clients/averspay';
import { config } from '../../../../clients/cognito';
import { User } from '../../userTypes';
import {
  AdminUpdateUserRequest,
  getRootUserFailure,
  getRootUserSucceeded,
  GetUser,
  GetUserAction,
  GetUserActionTypes,
  getUserFailure,
  getUserSucceeded,
  ManagerUpdateUserRequest,
  OperatorUpdateUserRequest,
  updateUserFailed,
  updateUserSucceeded
} from './actions';
import { getRootUserQuery, getUserQuery } from './queries';
import {
  updateUserForAdmin,
  updateUserForComplianceManager,
  updateUserForManager,
  updateUserForOperator,
} from './updateUserRequest';

export default function getUserEpic( action$ ) {
  return action$.pipe(
    filter( ( action: GetUserAction ) => action.type === GetUserActionTypes.GET_USER_REQUEST ),
    switchMap( ( action: GetUser ) =>
      getUser( action )
        .then( getUserSucceeded )
        .catch( ( error: Error ) => getUserFailure( error ) ),
    ),
  );
}

export function getRootUserEpic( action$ ) {
  return action$.pipe(
    filter( ( action: GetUserAction ) => action.type === GetUserActionTypes.GET_ROOT_USER_REQUEST ),
    switchMap( () =>
      getRootUser()
        .then( getRootUserSucceeded )
        .catch( ( error: Error ) => getRootUserFailure( error ) ),
    ),
  );
}

export async function getRootUser(): Promise<User> {
  const graphQLClient = await getClient();

  const {
    //@ts-ignore
    data: { getUser },
  } = await graphQLClient.query( {
    query: getRootUserQuery,
    variables: {
      input: {
        id: config.AVERSPAY_USER_ID,
      },
    },
  } );

  return getUser;
}

export async function getUser( { id }: GetUser ): Promise<User> {
  const graphQLClient = await getClient();

  const {
    //@ts-ignore
    data: { getUser },
  } = await graphQLClient.query( {
    query: getUserQuery,
    variables: {
      input: {
        id,
      },
    },
  } );

  return getUser;
}

export function updateUserForOperatorEpic( action$ ) {
  return action$.pipe(
    filter( ( action: GetUserAction ) => action.type === GetUserActionTypes.OPERATOR_UPDATE_USER_REQUEST ),
    switchMap( ( action: OperatorUpdateUserRequest ) =>
      updateUserForOperator( action )
        .then( ( data ) => updateUserSucceeded( data ) )
        .catch( ( error ) => updateUserFailed( error ) ),
    ),
  );
}

export function updateUserForAdminEpic( action$ ) {
  return action$.pipe(
    filter( ( action: GetUserAction ) => action.type === GetUserActionTypes.ADMIN_UPDATE_USER_REQUEST ),
    switchMap( ( action: AdminUpdateUserRequest ) =>
      updateUserForAdmin( action )
        .then( ( data ) => updateUserSucceeded( data ) )
        .catch( ( error ) => updateUserFailed( error ) ),
    ),
  );
}

export function updateUserForManagerEpic( action$ ) {
  return action$.pipe(
    filter( ( action: GetUserAction ) => action.type === GetUserActionTypes.MANAGER_UPDATE_USER_REQUEST ),
    switchMap( ( action: ManagerUpdateUserRequest ) =>
      updateUserForManager( action )
        .then( ( data ) => updateUserSucceeded( data ) )
        .catch( ( error ) => updateUserFailed( error ) ),
    ),
  );
}

export function updateUserForComplianceManagerEpic( action$ ) {
  return action$.pipe(
    filter( ( action: GetUserAction ) => action.type === GetUserActionTypes.COMPLIANCE_MANAGER_UPDATE_USER_REQUEST ),
    switchMap( ( action: ManagerUpdateUserRequest ) =>
      updateUserForComplianceManager( action )
        .then( ( data ) => updateUserSucceeded( data ) )
        .catch( ( error ) => updateUserFailed( error ) ),
    ),
  );
}
