/* External dependencies */
import { Text } from 'atomize';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import { Locale } from '../../locales/locales';
import { isMoneyValue } from '../acquiring/acquiring-payment/helper';
import { CreateAnOrderValidLengthRequiredException } from '../acquiring/acquiring-payment/redux/exceptions';
import { getUserRole } from '../common/helpers';
import { UserRole } from '../common/roles';
import { SessionUser } from '../login/redux/actions';
import { ServiceStatus } from './types';

export function getManagerFields(language) {
  const { t } = useTranslation();
  let name = {};

  switch (language) {
    case Locale.RU:
      name = {
        field: 'name_ru',
        title: t('name_ru'),
      };
      break;
    case Locale.KY:
      name = {
        field: 'name_ky',
        title: t('name_ky'),
      };
      break;
    case Locale.EN:
      name = {
        field: 'name_en',
        title: t('name_en'),
      };
      break;
    default:
      name = {
        field: 'name_ru',
        title: t('name_ru'),
      };
      break;
  }

  return [
    {
      field: 'logo',
      title: t('logo'),
    },
    {
      field: 'id',
      title: 'ID',
    },
    name,
    {
      field: 'popularityScore',
      title: t('popularityScore'),
    },
    {
      field: 'statusService',
      title: t('status'),
    },
  ];
}

export function getServiceStatusColor(status) {
  let statusColor = {
    bgColor: '',
    border: '',
    textColor: '',
  };

  switch (status) {
    case ServiceStatus.DISABLED:
      return (statusColor = { bgColor: 'pending_bg', textColor: 'pending_text_color', border: '#FFAA00' });
    case ServiceStatus.ENABLED:
      return (statusColor = {
        bgColor: 'succeeded_bg',
        textColor: 'succeeded_text_color',
        border: '#00D68F',
      });
    case ServiceStatus.INACTIVE:
      return (statusColor = { bgColor: 'failed_bg', textColor: 'failed_text_color', border: '#FF3D71' });

    default:
      return statusColor;
  }
}

export enum ActionsServicesMenuList {
  BLOCK = 'BLOCK',
  ACTIVATE = 'ACTIVATE',
  EDIT = 'EDIT',
  CREATESERVICE = 'createService',
  SET_FEE = 'setFee',
  SET_FEE_DISTRIBUTION = 'setFeeDistribution',
  CREATECAREGORY = 'createCategory',
  DELETE = 'deleteService',
}

export const servicesFilters = [
  {
    label: 'ALL',
    value: JSON.stringify('ALL'),
  },
  {
    label: 'ACTIVEPLURAL',
    value: JSON.stringify(ServiceStatus.ENABLED),
  },
  {
    label: 'BLOCKPLURAL',
    value: JSON.stringify(ServiceStatus.DISABLED),
  },
  {
    label: 'INACTIVEPLURAL',
    value: JSON.stringify(ServiceStatus.INACTIVE),
  },
];

export function getUserSubMenuLinks(currentUser: SessionUser) {
  const userRole = getUserRole(currentUser);

  switch (userRole) {
    case UserRole.MANAGER:
      return getManagerSubLinks();
    case UserRole.ADMIN:
      return getManagerSubLinks();
    default:
      throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
  }
}

export function getManagerSubLinks() {
  const sublinks = [
    {
      key: ServicesSteps.STEP_ONE_CREATE_SERVICE,
      menuItem: ServicesSteps.STEP_ONE_CREATE_SERVICE,
    },
    {
      key: ServicesSteps.STEP_TWO_CREATE_SERVICE_INFORMATION,
      menuItem: ServicesSteps.STEP_TWO_CREATE_SERVICE_INFORMATION,
    },
    {
      key: ServicesSteps.STEP_THREE_CREATE_SERVICE_INFO_CARD,
      menuItem: ServicesSteps.STEP_THREE_CREATE_SERVICE_INFO_CARD,
    },
  ];

  return sublinks;
}

export function returnNameService(language, obj) {
  switch (language) {
    case Locale.RU:
      return obj?.name_ru ? obj?.name_ru : obj?.label_ru;
    case Locale.KY:
      return obj?.name_ky ? obj?.name_ky : obj?.label_ky;
    case Locale.EN:
      return obj?.name_en ? obj?.name_en : obj?.label_en;
    default:
      return;
  }
}

export enum ServicesPaths {
  SERVICES = '/admin/services',
  CREATE_SERVICE = '/admin/services/create-service',
  CREATE_SERVICE_ENDPOINT = '/admin/services/create-service-endpoint',
  UPDATE_SERVICE = '/admin/services/update-service/',
  ACQUIRING_SERVICES = '/acquiring/services/',
}

export enum ServicesSteps {
  STEP_ONE_CREATE_SERVICE = 'stepOneCreateService',
  STEP_TWO_CREATE_SERVICE_INFORMATION = 'stepTwoCreateServiceInformation',
  STEP_THREE_CREATE_SERVICE_INFO_CARD = 'stepThreeCreateServiceInfoCard',
}

export function clickBackNavService(submenuItem: string, setBackNavPopup, onClickNextPage) {
  switch (submenuItem) {
    case ServicesSteps.STEP_ONE_CREATE_SERVICE:
      return setBackNavPopup(true);
    case ServicesSteps.STEP_TWO_CREATE_SERVICE_INFORMATION:
      return onClickNextPage(ServicesSteps.STEP_ONE_CREATE_SERVICE);
    case ServicesSteps.STEP_THREE_CREATE_SERVICE_INFO_CARD:
      return onClickNextPage(ServicesSteps.STEP_TWO_CREATE_SERVICE_INFORMATION);
    default:
      return '';
  }
}

export function convertToCamelCase(str) {
  const words = str.split(' ');

  // Convert the first word to lowercase
  let outputString = words[0].toLowerCase();

  // Capitalize the first letter of each subsequent word
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
    outputString += capitalizedWord;
  }

  return outputString;
}

export function getErrorTranslatedMessage(errorMessage: string) {
  const errorsMapping = {
    'Not Authorized to access updateCategory on type Mutation': 'UnauthorizedToDeleteCategory',
  };

  if (errorsMapping.hasOwnProperty(errorMessage)) {
    return <Trans>{errorsMapping[errorMessage]}</Trans>;
  }

  return errorMessage;
}

export function returnValidError(input: any, rest: any, t: (...data: any) => string) {
  const name = input?.inputName + 'Error';
  const errorObj = rest[name];
  const errorMessage = errorObj?.error;
  const minVal = errorObj?.min;
  const maxVal = errorObj?.max;

  let errorCode;

  if (errorObj) {
    if (isMoneyValue(input.inputName)) {
      errorCode = (
        <>
          <Text textColor="inherit">{minVal ? t('minSum', { length: minVal }) : ''}</Text>
          <Text textColor="inherit">{maxVal ? t('maxSum', { length: maxVal }) : ''}</Text>
        </>
      );
    } else {
      errorCode =
        minVal || maxVal
          ? `${t(new CreateAnOrderValidLengthRequiredException().code)} ${t('inRange')} ${minVal ? minVal : '0'} ${
              maxVal ? '-' + maxVal : ''
            }`
          : t(errorMessage?.code);
    }
  }

  return errorCode;
}

export function setSelectedServicetoLocalStorage(selectedServices, services, size) {
  let data;

  if (size <= 20) {
    data = selectedServices.map((item) => {
      return { id: item.id };
    });
  } else {
    data = services.map((item) => {
      return {
        id: item.id,
      };
    });
  }

  localStorage.setItem('selectedServices', JSON.stringify(data));
}

export function getEditServicePermission(currentUser) {
  const userRole = getUserRole(currentUser);

  return [UserRole.MANAGER, UserRole.ADMIN].includes(userRole);
}
